@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");

body {
	font-family: "Assistant", sans-serif;
}

.btn-check:focus+.btn,
.btn:focus {
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.App {
	overflow: hidden;
}

header {
	min-height: 40px;
	line-height: 40px;
	background: #2c2c2c;
}

header span {
	color: #fff;
}

header span span {
	color: #969696;
}

header .btn-group {
	background: #fff;
	padding: 1px;
	border-radius: 38px;
	min-width: 92px;
}

header .btn {
	padding: 0;
	border-radius: 38px 0px 0px 38px;
	font: normal normal bold 14px/24px Assistant;
}

.btn-pm {
	background-color: #f74646;
	border-color: #f74646;
	color: #fff;
}

.column {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.banner {
	padding-top: 40px;
	position: relative;
}

.banner_all {
	background-color: #000000;
}

.banner.gradient-bg::before,
.banner.gradient-bg::after {
	position: absolute;
	width: 50%;
	height: 100%;
	top: 0;
}

.banner.gradient-bg::before {
	content: "";
	left: 0;
	opacity: 0.5;
}

.banner.gradient-bg::after {
	content: "";
	right: 0;
	opacity: 0.5;
}

.banner.gradient_bg_all::before {
	background: transparent radial-gradient(closest-side at 50% 50%, #d6129a 0%, #d6129a00 100%) 0% 0% no-repeat padding-box;
}

.banner.gradient_bg_all::after {
	background: transparent radial-gradient(closest-side at 50% 50%, #126cd6 0%, #126cd600 100%) 0% 0% no-repeat padding-box;
}

.banner-title {
	color: #fff;
	font-weight: bold;
	line-height: 1.2;
}

.banner-title span:first-child {
	color: #fff;
	font-weight: bold;
	font-size: 40px;
	margin-right: 8px;
}

.banner-title span:last-child {
	color: #969696;
}

.curve {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background: transparent;
	height: 9rem;
	width: 15rem;
	border-radius: 12px 0 0 12px;
	overflow: hidden;
	padding: 16px 0 16px 10px;
	margin-right: 20px;
}

.curve img {
	max-width: 100%;
	max-height: 100%;
}

.ir {
	font-size: 32px;
	color: #969696;
}

.details {
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	margin-right: 8px;
	display: flex;
	justify-content: start;
	flex-wrap: wrap;
}

.details-title {
	color: #969696;
	margin-left: 0.8rem;
}

.p-0-20 {
	padding: 0 20px;
}

.pl-210 {
	padding-left: 210px;
	position: relative;
	z-index: 1;
}

.about {
	margin-top: 3rem;
	max-width: 80%;
}

.about .main-title {
	position: absolute;
	left: 60px;
	color: #fff;
	font-size: 24px;
	font-weight: bold;
}

.about .heading {
	font-size: 14px;
	color: #969696;
	text-transform: uppercase;
	font-weight: bold;
}

.about .description {
	font-size: 20px;
	color: #fff;
	font-weight: bold;
}

.about .info {
	margin-bottom: 15px;
}

.about .btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	gap: 5px;
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 20px;
	opacity: 0.7;
	width: 150px;
	margin-bottom: 10px;
	margin-right: 1rem;
	font-weight: 400;
	color: #fff;
	text-align: left;
}

.about .cl button img {
	height: 20px;
	width: 20px;
}

.card-c {
	background: #fff;
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	-webkit-transition: 0.3s;
	transition: 0.3s;
	border-radius: 16px;
	border: none;
	padding: 17px 25px;
	margin-bottom: 15px;
}

.card-c-details {
	display: -webkit-box;
	display: -ms-flexbox;
	-ms-flex-preferred-size: 25%;
	flex-basis: 25%;
	gap: 10px;
}

.card-c-details .left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.card-c-details .right {
	width: 15rem;
}

.card-c-details .right button {
	border: 1px solid #000000;
	border-radius: 50px;
	font-weight: 600;
	padding: 5px 20px;
	display: inline-block;
	font-size: 21px;
	font-weight: bold;
	display: block;
	width: 14rem;
	margin: 0 0 12px auto;
}

.card-c-details .right button img {
	width: 22px;
	height: 22px;
	border-radius: 0;
	margin-right: 10px;
}

.card-c-details .right p {
	font-size: 15px;
	text-align: center;
}

.card-c-details .details-title {
	text-transform: uppercase;
	color: #000000;
	font-size: 21px;
	font-weight: 700;
}

.card-c-details .details-sub-title {
	color: #000000;
	font-size: 20px;
	margin-bottom: 10px;
}

.card-c .top-title {
	font-size: 21px;
	font-weight: 700;
	margin-bottom: 10px;
}

.card-c-details .img-wrapper {
	position: relative;
	width: max-content;
}

.card-c-details .img-wrapper .btn-expand {
	position: absolute;
	right: 10px;
	top: 10px;
	border: 0;
	padding: 0;
	background-color: transparent;
	border-radius: 20px;
	overflow: hidden;
}

.card-c-details .img-wrapper .btn-expand img {
	width: 40px;
}

.card-c-details .img-wrapper .car-img {
	max-width: 25rem;
	max-height: 16rem;
}

.card-c-details .img-wrapper .img-tag {
	position: absolute;
	bottom: 10px;
	right: 10px;
	background: rgb(25, 190, 25);
	color: #fff;
	padding: 3px 5px;
	border-radius: 5px;
	font-size: 14px;
}

.card-c img {
	border-radius: 16px;
	-o-object-fit: cover;
	object-fit: cover;
}

.card-c .price {
	font-size: 40px;
	font-weight: bold;
	text-align: center;
}

.card:hover {
	-webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-text {
	margin-left: 20px;
}

.ml-8 {
	margin-left: 8px;
}

.p-20 {
	padding: 20px;
}

.pb-50 {
	padding-bottom: 50px;
}

.text-right {
	text-align: right;
}

.bg-black {
	background: #000000;
}

.btn-circle {
	background: #fff;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	display: -ms-grid;
	display: grid;
	place-items: center;
	margin-right: 7px;
}

.btn-circle img {
	width: 12px;
}

.accordian-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	color: #fff;
	font-weight: 300;
	font-size: 25px;
	margin-bottom: 15px;
}

.accordian-title a {
	color: #fff;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none;
	font-size: 30px;
	font-weight: 600;
}

.btn-align {
	position: relative;
	top: -25px;
}

.switch {
	position: relative;
	display: inline-block;
	width: 140px;
	height: 40px;
	border-radius: 16px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	border-radius: 50px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: transparent;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border: 1px solid #000000;
	width: 140px;
	height: 40px;
}

.slider:before {
	position: absolute;
	content: "Yes";
	height: 31px;
	width: 70px;
	left: 5px;
	bottom: 4px;
	background-color: black;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 26px;
	text-align: center;
	color: #fff;
}

.slider::after {
	position: absolute;
	right: 16px;
	top: 3px;
	content: "No";
}

input:checked+.slider {
	background-color: #fff;
}

input:focus+.slider {
	-webkit-box-shadow: 0 0 1px #fff;
	box-shadow: 0 0 1px #fff;
}

input:checked+.slider:before {
	-webkit-transform: translateX(58px);
	transform: translateX(58px);
	content: "No";
}

input:checked+.slider::after {
	position: absolute;
	left: 16px;
	top: 3px;
	content: "Yes";
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 50px;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 35px;
}

.label span:first-child {
	width: calc(100% - 140px);
}

.gap-100 {
	gap: 100px;
}

.btn-radius {
	border-radius: 26px;
	border: 1px solid;
	font-size: 17px;
	font-weight: 600;
	width: 100%;
	max-width: 25%;
	position: relative;
}

.btn-radius span {
	position: absolute;
	left: 10px;
	top: 4px;
}

.btn-radius img {
	width: 70%;
	height: auto;
}

.mt-20 {
	margin-top: 20px;
}

[aria-expanded="true"] .btn-circle img {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.btn-circle.rotate img {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.slider-container {
	position: relative;
	z-index: 1;
	padding: 60px 0 60px 60px;
}

.slider-container .slider-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.slider-container .slider-header .slider-title {
	color: #fff;
	font-size: 24px;
	font-weight: bold;
	flex-basis: 30%;
}

.slider-container .slider-header .slider-title span+span {
	padding-left: 10px;
}

.slider-container .slider-header .slider-title .grey-color {
	color: #969696;
}

.slider-container .slider-header .slider-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-left: 60px;
	flex-basis: 60%;
	overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slider-container .slider-header .slider-filter::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider-container .slider-header .slider-filter {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}


.slider-container .slider-header .slider-filter .filter-tag {
	color: #969696;
	padding: 3px 28px;
	margin: 0 7px;
	cursor: pointer;
	font-size: 17px;
	text-decoration: none;
	white-space: nowrap;
}

.slider-container .slider-header .slider-filter .filter-tag:hover,
.slider-container .slider-header .slider-filter .filter-tag.active {
	background: #f74646;
	color: #fff;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 32px;
}

.slider-container .slider-header .slider-filter .filter-tag.active {
	font-weight: bold;
}

.slider-container .slider-photos {
	padding-top: 30px;
	padding-right: 2.325rem;
}

.slider-container .slider-header {
	padding-right: 2.325rem;
}

.slider-container .owl-carousel .owl-stage {
	padding-left: 0 !important;
}

.slider-container .owl-nav {
	position: absolute;
	width: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.slider-container .owl-nav .owl-prev,
.slider-container .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 70px;
	height: 70px;
	border-radius: 35px;
	background-color: #fff !important;
	-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	font-size: 50px !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.slider-container .owl-nav .owl-prev img,
.slider-container .owl-nav .owl-next img {
	width: 32px;
}

.slider-container .owl-nav .owl-prev {
	left: -50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.slider-container .owl-nav .owl-next {
	right: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.slider-container .owl-dots {
	display: none;
}

.slider-container .slider-counter {
	color: #fff;
	font-size: 20px;
	position: absolute;
	margin-top: 20px;
	border: 1px solid #fff;
	padding: 1px 20px;
	border-radius: 20px;
}

.slider-container .slider-view-all {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 60px;
}

.slider-container .slider-view-all button {
	background-color: #f74646;
	color: #fff;
	border-radius: 40px;
	padding: 6px 30px;
	font-size: 17px;
	font-weight: bold;
	text-decoration: none;
	margin-top: 20px;
	border: none;
}

.car-photo {
	/* padding: 20px; */
	border-radius: 16px;
	background-color: #fff;
	position: relative;
	overflow: hidden;
}

.car-photo img {
	max-width: 100%;
}

.car-photo .btn-expand {
	position: absolute;
	right: 10px;
	top: 10px;
	border: 0;
	padding: 0;
	background-color: transparent;
	border-radius: 20px;
	overflow: hidden;
}

.car-photo .btn-expand img {
	width: 40px;
}

.minimize-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.minimize-btn a,
.minimize-btn button {
	background-color: #fff;
	color: #000000;
	border-radius: 40px;
	padding: 6px 75px;
	text-decoration: none;
	margin-top: 20px;
	font-size: 20px;
	margin-bottom: 30px;
	font-weight: bold;
	border: none;
}

.disclaimer .block-title {
	font-size: 30px;
	font-weight: 600;
	color: #fff;
}

.disclaimer .disclaimer-content {
	background-color: #fff;
	border-radius: 16px;
	padding: 40px;
	margin-top: 15px;
}

.disclaimer .disclaimer-content ol li {
	margin-bottom: 20px;
	font-size: 24px;
}

.disclaimer .check-list {
	font-size: 24px;
	margin-left: 30px;
}

.disclaimer .check-list img {
	width: 30px;
	margin-right: 10px;
}

.disclaimer .check-list div+div {
	margin-top: 20px;
}

.disclaimer .customer-sign {
	padding: 30px;
}

.disclaimer .customer-sign .title {
	font-size: 30px;
	font-weight: 600;
	margin-bottom: 20px;
}

.disclaimer .customer-sign .sign-container {
	background-color: #f5f5f7;
	padding: 50px;
	border-radius: 16px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.disclaimer .customer-sign .sign-container img {
	height: 120px;
}

.modal-header {
	border: 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 0.5rem;
}

.modal-title {
	text-transform: uppercase;
	font-size: 28px;
	font-weight: bold;
	color: #000000;
}

@media (min-width:992px) {
	.all_damage_modal_title {
		padding-left: 127px;
	}
}

.modal-close {
	border: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	padding: 0;
}

.modal-close img {
	width: 50px;
}

.modal-body {
	max-height: 65vh;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
}

.modal-body .modal-img {
	max-height: 60vh;
	min-height: 40vh;
}

.modal-body .img-wrapper {
	overflow: hidden;
	min-width: 75vw;
	min-height: 40vh;
}

.modal-content {
	padding: 1rem 2rem;
	overflow: hidden;
	border: 0;
	border-radius: 20px;
}

.modal-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-footer,
.footer-content {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border: 0;
}

.modal-footer span,
.footer-content span {
	font-size: 28px;
	color: #000000;
	margin-left: 20px;
}

.btn-view {
	border: 0;
	border-radius: 30px;
	/* background-color: #000000; */
	color: #fff;
	padding: 10px 40px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 20px;
}

.view_btn_all {
	background-color: #000000;
}

.powered-by {
	color: #fff;
	font-size: 24px;
	padding: 60px 0;
}

.powered-by img {
	max-width: 150px;
	margin-bottom: 20px;
}

.margin-top {
	margin-top: 15rem;
}

.view-all-damages-modal .modal-content {
	background-color: #f5f5f7;
}

.view-all-damages-modal .modal-body {
	overflow: auto;
	height: 70vh;
}

.view-all-damages-modal .footer-content {
	padding: 10px 0;
}

.view-all-damages-modal .btn-back {
	position: absolute;
	right: 10px;
	top: 10px;
}

.view-all-damages-modal .car-photo {
	padding: 0;
}

.view-all-damages-modal .car-photo img {
	border-radius: 10px;
}

.view-all-damages-modal .car-photo .btn-expand {
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
}

.view-all-damages-modal h3 {
	text-align: left;
	font-weight: bold;
}

.view-all-damages-modal .row {
	--bs-gutter-y: 24px;
}

.container-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.container-checkbox input:checked~.checkmark {
	background-color: #000000;
}

.container-checkbox input:checked~.checkmark:after {
	display: block;
}

.container-checkbox .checkmark {
	position: absolute;
	top: 4px;
	left: 4px;
	height: 26px;
	width: 26px;
	border-radius: 100%;
	background-color: #000000;
}

.container-checkbox .checkmark::after {
	content: "";
	position: absolute;
	display: none;
	left: -2px;
	top: -2px;
	width: 30px;
	height: 30px;
	/* background: url('/logo512.png') no-repeat 0 0; */
	background-size: 100%;
}



.Modal {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	outline: none;
	border: none;
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(26, 23, 23, 0.823);
	z-index: 100;
}

.react-multiple-carousel__arrow {
	background: #fff !important;
	min-width: 60px !important;
	min-height: 60px !important;
	font-size: 25px !important;
}

.react-multiple-carousel__arrow::before {
	color: #000 !important;
}

.input-position {
	position: relative;
	min-width: 30vw;
}

.capsule {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 140px;
	height: 40px;
	border-radius: 30px;
	padding: 0 5px;
	border: 1px solid black;
}

.capsule .content {
	height: 31px;
	width: 70px;
	background-color: #fff;
	border-radius: 26px;
	text-align: center;
	color: #000;
}

.capsule .active {
	background-color: black;
	color: #fff;
}

.questions .part {
	flex: 0.5;
}

button:disabled {
	cursor: not-allowed;
	pointer-events: all !important;
}

.image-item {
	padding: 20px 0 20px 20px;
}

.vis-off {
	visibility: hidden;
}

.view_all_modal_img_head {
	margin-bottom: 0.5rem;
	text-align: left;
}


/* Garage_Plug Theme */


.banner_garage_plug {
	background: rgb(18, 146, 111);
	background: linear-gradient(90deg, rgba(18, 146, 111, 1) 0%, rgba(196, 237, 237, 0.9948354341736695) 52%, rgba(235, 255, 250, 1) 100%);
}

.banner_garage_plug .banner-title {
	color: #333;
}

.banner_garage_plug .banner-title span {
	color: #333;
}

.banner_garage_plug .banner-title span:last-child {
	color: #333;
}

.banner_garage_plug .ir {
	color: #333;
	font-weight: 600;
}

.banner_garage_plug .details {
	color: #333;
}

.banner_garage_plug .details-title {
	color: #333;
}

.banner_garage_plug .about .heading {
	color: #424242;
}

.banner_garage_plug .about .main-title {
	color: #333;
}

.banner_garage_plug .about .description {
	color: #333;

}

.banner_garage_plug .slider-container .slider-header .slider-title .grey-color {
	color: #424242;
}

.banner_garage_plug .slider-container .slider-header .slider-title {
	color: #333;
}

.banner_garage_plug .slider-container .slider-header .slider-filter .filter-tag {
	color: #424242;
	font-weight: 500;
}

.banner_garage_plug .slider-container .slider-header .slider-filter .filter-tag:hover,
.banner_garage_plug .slider-container .slider-header .slider-filter .filter-tag.active {
	background-color: #12926f;
	color: #fff;
}

.banner_garage_plug .slider-container .slider-view-all button {
	background-color: #12926f;
}

.garage_plug_report_outer .accordian-title {
	color: #333;
	font-weight: 500;
}

.garage_plug_report_outer .right_side_buttons {
	position: absolute;
	bottom: 0.1rem;
	right: 0;
}

.garage_plug_report_outer .right_side_buttons button {
	font-weight: 500;
	background-color: #099370;
}

.garage_plug_report_outer .minimize-btn button {
	box-shadow: 0.25rem 0.25rem 0.35rem #bdbdbd;
}

.garage_plug_report_outer .bg-black {
	background: rgb(18, 146, 111);
	background: linear-gradient(90deg, rgba(18, 146, 111, 1) 0%, rgba(196, 237, 237, 0.9948354341736695) 52%, rgba(235, 255, 250, 1) 100%);
}

.view_btn_garage {
	background-color: #12926f;
}


/* Responsive */

@media (max-width:1199px) {

	.logo_outer {
		position: absolute;
		right: 0;
		top: 0;
	}

	.card-c-details .img-wrapper {
		margin-right: 1.5rem;
	}

	.card-c-details .card-text {
		margin-top: 1.1025rem;
		margin-left: 0;
	}

	.card-c-details .img-wrapper .btn-expand {
		width: max-content;
		left: 1rem;
		right: 0;
	}

	.card-c-details .right {
		width: 100%;
		margin-top: 2rem;
	}

	.card-c-details .right button {
		margin: 0 0 0 auto;
	}

	.card-c-details .right button:not(:last-child) {
		margin-bottom: 1.1025rem;
	}


	.head_column_outer {
		padding: 0 2rem;
	}


	.vehicle_info_outer {
		padding-top: 9rem;
	}

	.vehicle_info_part {
		display: block;
	}

	.vehicle_about_outer {
		/* position: relative; */
		flex-wrap: wrap;
		padding-top: 2rem;
		justify-content: flex-start;
		/* border: red solid 1px; */
		width: calc(100% - 9rem);
		max-height: 17rem;
		overflow-y: auto;
	}

	/* .vehicle_about_inner {
		max-height: 20rem;
		overflow-y: auto;

	} */

	.vehicle_about_outer .main-title {
		position: static;
		width: 100%;
		height: max-content;
		margin-top: -2rem;
		margin-bottom: 0.75rem;
	}

	.vehicle_about_outer .right_side_buttons {
		position: absolute;
		right: 0;
	}

	.vehicle_about_outer .vehicle_data_inner {
		width: 16rem;
		margin-top: 0;
	}


	.slider-container {
		padding: 2rem 0 2rem 2rem;
	}

	.slider-container .slider-header {
		display: block;
	}

	.slider-container .slider-header .slider-filter {
		margin: 1rem 0 0 0;
		flex-basis: 100%;
	}

	header .header {
		display: block;
	}

	.header_inner_section {
		width: 100%;
	}

	.language_button_outer {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
	}

	.vis-off {
		display: none;
	}

	.accordian_modal h5,
	.slider_modal_outer h5 {
		font-size: 1.4rem;
	}

	.view_all_modal_img_head {
		font-size: 1.3rem;
		text-align: left;
	}

	.modal-dialog {
		max-width: 95vw !important;
	}

	.modal-content {
		max-height: 90vh;
	}

	.modal-body {
		overflow-x: auto;
	}
}

@media (max-width:991px) {
	.card-c-details .left {
		display: block;
	}

	.card-c-details .img-wrapper {
		margin: 0 0 2rem 0;
	}

	.react-multiple-carousel__arrow {
		min-width: 3rem !important;
		min-height: 3rem !important;
		background-color: transparent !important;
		font-weight: 800;
	}

	.react-multiple-carousel__arrow::before {
		color: #f74646 !important;
		margin-top: 1.25rem;
	}

	.react-multiple-carousel__arrow--left::before {
		margin-left: -1rem;
	}

	.react-multiple-carousel__arrow--right::before {
		margin-right: -3rem;
	}

	.slider-container .slider-view-all {
		padding-right: 2rem;
	}

	.car-photo .btn-expand img {
		width: 1.75rem;
		border-radius: 100%;
	}

	.car-photo .btn-expand {
		top: 0.2rem;
		right: 0.25rem;
	}

	.accordian-title .accordian_name {
		font-size: 1.25rem;
		padding-left: 0.35rem;
	}

	.total_damages_count {
		font-size: 1.25rem;
	}

	.slider_modal_outer .modal-footer {
		display: block;
		padding: 1.5rem 0;
	}

	.slider_modal_outer .modal-footer span {
		display: block;
		font-size: 1.3rem;
	}

	.slider_modal_outer .modal-footer .btn-view {
		margin: 0 auto;
		display: block;
		padding: 0.5rem 1rem !important;
		font-size: 1.1rem;
	}

	.view_all_modal_outer .btn-view {
		padding: 0.5rem 1rem !important;
		font-size: 1.1rem;
	}

	.view_all_modal_outer .modal-footer span {
		text-align: left;
		margin: 0.5rem 0 2rem 0;
	}

	.logo_outer {
		padding: 0 !important;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}

	.logo_outer .curve {
		margin: 0 auto;
	}

	.modal-title {
		padding-right: 0.7rem;
	}
}

@media (max-width:767px) {

	.accordian_modal h5,
	.slider_modal_outer h5 {
		font-size: 1.3rem;
	}
}

@media (max-width:575px) {

	.details {
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		margin-right: 8px;
	}

	.details-title {
		color: #969696;
		margin-left: 0;
	}

	.vin-detail {
		margin-left: -0.45rem;
	}

	.card-c-details .img-wrapper {
		width: 100%;
	}

	.card-c-details .img-wrapper .car-img {
		max-width: 100%;
		max-height: 16rem;
	}

	.accordian-title {
		display: block;
	}

	.accordian-title a {
		justify-content: flex-start !important;

	}

	.accordian-title .total_damages_count {
		text-align: right;
	}

	.quesAns_part_outer {
		display: block;
	}
}

@media (max-width:387px) {
	.card-c-details .right button {
		width: 100%;
		margin: 0 auto;
	}
}

.test {
	position: relative;
}

.custom_snackbar {
	position: fixed;
	top: 2rem;
	right: 3rem;
	background-color: #F8D7D9;
	color: white;
	padding: 1rem 2rem;
	border-radius: 0.4rem;
	transition: opacity 0.5s, transform 0.5s;
	z-index: 999;
	border: #d45c54 solid 0.1rem;
	color: #d45c54;
}