.main_choose_location_outer {
    padding: 3rem 2rem;
    height: 100vh;
}

.main_choose_location_inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.choose_location_inner {
    width: 30rem;
    margin: 0 auto;
}

.confirm_button {
    margin-top: 5rem;
    background-color: #FF7A00;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    transition: background-color linear 0.4s;
}

.confirm_button:hover {
    color: #fff;
    background-color: #fd8413e6;
}

.label {
    font-size: 1.3rem;
    color: #b1b1b1;
}