.autocomplete-dropdown-container {
    overflow: auto;
}

.location-search-input {
    padding: 0.45rem 0.85rem;
    border: none;
    width: 100%;
    outline: none;
    font-size: 1.4rem;
    font-weight: 500;
    border-bottom: 0.12rem solid #333;
}


.suggestion-item,
.suggestion-item--active {
    border-radius: 0.5rem;
    padding: 0.25rem 0.8rem;
    margin: 0.5rem 0;
    font-size: 1.2rem;
    cursor: pointer;
}

.suggestion-item--active {
    background-color: #c3c3c3;
}

.suggestion-item:first-child {
    background-color: #FF7A00;
    color: #fff;
}

.suggestion-item:last-child,
.suggestion-item--active:last-child {
    margin-bottom: 1rem;
}

.autocomplete_dropdown_container_inner {
    background-color: #f2f2f2;
    margin: 0.25rem 0 0 0;
    border-radius: 0.5rem;
}


@media (max-height:400px) {
    .autocomplete-dropdown-container {
        max-height: 13rem;
    }
}