@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
* {
  font-family: "Assistant", sans-serif;
}

.cursor-pointer, .main_body_outer header .custom_container .right_part button {
  cursor: pointer;
}

@media (max-width: 1199px) {
  html {
    font-size: 80%;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 70%;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 65%;
  }
}
@media (max-width: 575px) {
  html {
    font-size: 60%;
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  height: 100vh;
}

.m-0 {
  margin: 0;
}

.font_montserrat, .main_body_outer header .custom_container .right_part button {
  font-family: "Montserrat";
  font-style: normal;
}

.custom_container {
  height: 100%;
  width: 59rem;
  padding: 0 1rem;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .custom_container {
    width: calc(100vw - 4rem);
  }
}

.main_body_outer {
  height: 100vh;
  background-color: #f3f3f3;
}
.main_body_outer header {
  height: 5rem;
  background: linear-gradient(90deg, #bc0070 0%, #a6086a 4.63%, #940e65 8.02%, #7e165f 12.53%, #662058 17.51%, #512753 22.71%, #432b51 27.91%, #382d51 33.03%, #322d52 37.97%, #2a3055 43.25%, #23345b 48.09%, #193d66 53.45%, #0e4874 58.55%, #0d5581 63.73%, #0f638f 68.84%, #20749f 74.3%, #3585ae 78.94%, #3e94ba 84%, #45a3c7 89.02%, #45b3d5 94.32%, #44c2e1 99.48%);
}
.main_body_outer header .custom_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_body_outer header .custom_container .header_inner {
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_body_outer header .custom_container .left_part {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.main_body_outer header .custom_container .left_part .company_log {
  width: 5rem;
}
.main_body_outer header .custom_container .left_part .company_log img {
  width: 100%;
}
.main_body_outer header .custom_container .left_part .test_part {
  padding: 0 1rem 0 2.5rem;
}
.main_body_outer header .custom_container .left_part .test_part .head_part p {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
}
.main_body_outer header .custom_container .left_part .test_part .sub_part {
  margin-top: 0.2rem;
}
.main_body_outer header .custom_container .left_part .test_part .sub_part p {
  color: #c1c1c1;
  font-size: 1rem;
  font-weight: 400;
}
.main_body_outer header .custom_container .right_part button {
  outline: none;
  border: none;
  border-radius: 0.5rem;
  padding: 0.4rem 1.25rem;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.5rem -0.5rem #bdbdbd;
}
.main_body_outer header .custom_container .right_part button p {
  font-size: 1.3rem;
  font-weight: 500;
  background: linear-gradient(90deg, #bc0070 0%, #a6086a 4.63%, #940e65 8.02%, #7e165f 12.53%, #662058 17.51%, #512753 22.71%, #432b51 27.91%, #382d51 33.03%, #322d52 37.97%, #2a3055 43.25%, #23345b 48.09%, #193d66 53.45%, #0e4874 58.55%, #0d5581 63.73%, #0f638f 68.84%, #20749f 74.3%, #3585ae 78.94%, #3e94ba 84%, #45a3c7 89.02%, #45b3d5 94.32%, #44c2e1 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.main_body_outer footer {
  height: 8rem;
  padding: 1rem;
  margin-top: -1rem;
}
.main_body_outer footer img {
  margin: 0 0 0 auto;
  display: block;
  height: 2.75rem;
}
.main_body_outer footer p {
  text-align: right;
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
}
.main_body_outer .body_outer {
  height: calc(100vh - 13rem);
  padding: 1rem 0;
}
.main_body_outer .body_outer .custom_container {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.main_body_outer .body_outer .custom_container::-webkit-scrollbar {
  height: 0;
  width: 0.35rem;
}
.main_body_outer .body_outer .custom_container::-webkit-scrollbar-track {
  margin: 0 0;
  border-radius: 0;
  background: #dbdbdb;
}
.main_body_outer .body_outer .custom_container::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #bdbdbd;
}
.main_body_outer .body_outer .custom_container::-webkit-scrollbar-thumb:hover {
  background: #ababab;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab:not(:last-child) {
  margin-bottom: 1rem;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab-label {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #4f4d4d;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  /* Icon */
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab-label::after {
  content: "❯";
  color: #4f4d4d;
  font-size: 1.1rem;
  font-weight: 500;
  transform: rotate(-90deg);
  transition: all 0.35s;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab-content {
  max-height: 0;
  padding: 0 1rem;
  background-color: #fff;
  transition: all 0.35s;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .tab_label_input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .tab_label_input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .tab_label_input:checked ~ .tab-content {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  padding: 2rem 1rem;
  -o-border-image: linear-gradient(90deg, #bc0070 0%, #a6086a 4.63%, #940e65 8.02%, #7e165f 12.53%, #662058 17.51%, #512753 22.71%, #432b51 27.91%, #382d51 33.03%, #322d52 37.97%, #2a3055 43.25%, #23345b 48.09%, #193d66 53.45%, #0e4874 58.55%, #0d5581 63.73%, #0f638f 68.84%, #20749f 74.3%, #3585ae 78.94%, #3e94ba 84%, #45a3c7 89.02%, #45b3d5 94.32%, #44c2e1 99.48%) 30;
     border-image: linear-gradient(90deg, #bc0070 0%, #a6086a 4.63%, #940e65 8.02%, #7e165f 12.53%, #662058 17.51%, #512753 22.71%, #432b51 27.91%, #382d51 33.03%, #322d52 37.97%, #2a3055 43.25%, #23345b 48.09%, #193d66 53.45%, #0e4874 58.55%, #0d5581 63.73%, #0f638f 68.84%, #20749f 74.3%, #3585ae 78.94%, #3e94ba 84%, #45a3c7 89.02%, #45b3d5 94.32%, #44c2e1 99.48%) 30;
  border-width: 0.1rem;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer {
  margin: 0 -1rem -2rem -1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .text_inputs {
  margin: 0 1rem 2rem 1rem;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .text_inputs input {
  width: calc(100% - 1rem);
  outline: none;
  border: none;
  border-bottom: solid 0.1rem #d4d6d7;
  color: #333333;
  font-size: 1.2rem;
  font-weight: 500;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .text_inputs input::-moz-placeholder {
  color: #d4d6d7;
  font-size: 1rem;
  font-weight: 400;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .text_inputs input:-ms-input-placeholder {
  color: #d4d6d7;
  font-size: 1rem;
  font-weight: 400;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .text_inputs input::placeholder {
  color: #d4d6d7;
  font-size: 1rem;
  font-weight: 400;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .text_inputs input:focus {
  border: none;
  outline: none;
  border-bottom: solid 0.1rem #d4d6d7;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .half_inputs {
  width: calc(50% - 2rem);
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .full_inputs {
  width: calc(100% - 2rem);
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .radio_input_outer {
  margin: 0 1rem 2rem 1rem;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .radio_input_outer .qs_label {
  color: #d4d6d7;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
}
 .main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .radio_input_outer .radio_inputs_inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .radio_input_outer .radio_inputs_inner label {
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  padding-right: 2rem;
  cursor: pointer;
  text-transform: capitalize;
} 
 .main_body_outer .body_outer .custom_container .main_body_inner .tab .inputs_outer .radio_input_outer .radio_inputs_inner .radio_inputs {
  display: inline-block;
  position: relative;
}
label{
  padding-left: 10px;
}

input#declaration {
  margin-left: 20px;
}
#declaration-label {
  margin-left: 10px;
}  


/* # sourceMappingURL=style.css.map */